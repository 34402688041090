import { Layout, Dropdown, MenuProps, Form, Input, Button, Avatar } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SearchOutlined,
  LogoutOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import avatarImg from "../../../assets/images/avatar.png";
import { useAuth } from "../../../hooks/auth/AuthContext";

interface Props {
  collapsed: boolean;
  toggleSideNav?: () => void;
  logout?: () => void;
}

const GeneralHeader = ({ collapsed, toggleSideNav, logout }: Props) => {
  const { Header } = Layout;
  const { authState } = useAuth();
  const profileUrl = authState.userProfile !== null ? authState.userProfile.profileUrl : null;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link to="/profile/account">
          <FormattedMessage id="PROFILE" />
        </Link>
      ),
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: (
        <Link to="/profile/change-password">
          <FormattedMessage id="CHANGE_PASSWORD" />
        </Link>
      ),
      icon: <LockOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: "3",
      label: (
        <div onClick={logout}
          className="flex justify-between text-dangerButton"
          style={{ width: 185 }}
        >
          <FormattedMessage id="LOGOUT" />
          <LogoutOutlined />
        </div>
      ),
    },
  ];

  return (
    <Form>
      <Header
        className="flex gap-2 justify-around font-primary rounded-lg bg-white me-4 my-2 pt-2"
        style={{ height: "72px", paddingLeft: 20, paddingRight: 20 }}
      >
        <Button onClick={ toggleSideNav }
          icon={
            collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
          }
          style={{ width: "54px", height: "48px" }}
        />

        <div className="w-full">
          {/* <Form.Item>
            <Input placeholder="Search"
              prefix={
                <SearchOutlined style={{ fontSize: '20px' }} />
              }
              className="py-2"
            />
          </Form.Item> */}
        </div>

        <Dropdown
          menu={{ items }}
          trigger={["hover", "click"]}
          placement="bottomRight"
          className="cursor-pointer"
          open={dropdownVisible}
          onOpenChange={(visible) => setDropdownVisible(visible)}
        >
          <Avatar size={50} shape="square"
            src={
              <img src={profileUrl ?? avatarImg} alt="" />
            }
            style={{ margin:0 }}
          />
        </Dropdown>

      </Header>
    </Form>
  );
};

export default GeneralHeader;
