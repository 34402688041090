import { gql } from "@apollo/client";
export const LOGOUT = gql`
  mutation InternalSignOut {
    internalSignOut {
      status
    }
  }
`;

export const GET_EXTERNAL_SIDE_NAV_TREE = gql`
query ExternalSideNavTree($roleId:String, $isCreateTenant:Boolean, $tenantId:String){
    externalSideNavTree(roleId:$roleId, isCreateTenant: $isCreateTenant, tenantId: $tenantId){
        title,
        key,
        disableCheckbox,
        children
    }
}
`;

export const GET_ROLES_DROPDOWN = gql`
query ExternalUserRolesDropdown{
    externalUserRolesDropdown{
        id,
        value
    }
}
`;