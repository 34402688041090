import { gql } from "@apollo/client";

export const SIGN_IN = gql`
    mutation SignIn($userName: String!, $password: String!){
        signIn(userName: $userName, password: $password){
            a,
            r,
            sid,
            outlets {
                id,
                value
            },
            status
        }
    }
`;

export const REQUEST_OTP = gql`
mutation RequestOtp($verificationType:String!, $email:String!, $userName:String, $userNameNeeded:Boolean){
    requestOtp(verificationType:$verificationType, email:$email, userName:$userName, userNameNeeded:$userNameNeeded){
        status,
    }
}
`;

export const SIGN_IN_WITH_OTP = gql`
    mutation SignInWithOtp($userName: String!, $password: String!, $otp: String!, $email:String!, $verificationType:String!){
        signInWithOtp(userName: $userName, password: $password, otp:$otp, email:$email, verificationType:$verificationType){
            a,
            r,
            sid,
            outlets {
                id,
                value
            }
        }
    }
`;

export const GET_TENANT_USER_OUTLET_DROPDOWN = gql`
query TenantUserOutletDropdown{
    tenantUserOutletDropdown{
        id,
        value
    }
}
`;

export const UPDATE_CURRENT_OUTLET = gql`
    mutation UpdateCurrentOutlet($outletId: String!, $userName:String!){
        updateCurrentOutlet(outletId: $outletId, userName:$userName){
            status
        }
    }
`;

export const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email: String!, $newPassword:String!, $otp:String!, $verificationType:String!){
    forgotPassword(email: $email, newPassword:$newPassword, otp:$otp, verificationType:$verificationType){
        status
    }
}`;